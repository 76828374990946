:root {
  --darkblue: #1366a8;
}
html,
body,
#root {
  min-height: 101%;
}
body {
  margin: 0;
  font-family: Montserrat, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}
.custom-footer {
}
.navbar {
  // background: #0e6bb0 linear-gradient(180deg, #2588d2, #0e6bb0) repeat-x;
  background-color: var(--darkblue);
}
.navbar-brand {
  font-size: 18px;
}

h1 {
  margin-bottom: 0;
  font-size: 1.5rem;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
label {
  font-weight: 600;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}
.card {
  overflow: hidden;
}
.progress_text {
  font-size: 8px;
  color: #4c4c4c;
  position: absolute;
  opacity: 1;
  transition: opacity 1000ms ease-out;
  mix-blend-mode: difference;
  width: 100%;
  text-align: right;
  height: 10px;
  line-height: 10px;
  border-radius: 10px;
  top: 6px;
}
.overlay_loading {
  position: absolute;
  z-index: 999;
  height: 104%;
  width: 104%;
  left: -2%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffffb8;
}
h2,
.h2 {
  color: #bdbdbd;
}
.btn-group {
  display: flex;
  flex-direction: column;
}
.btn-group button {
  min-height: 50px;
  margin-right: 0;
  margin-bottom: 10px;
  background: white;
  padding: 8px 15px;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  text-transform: none;
  border-radius: 5px !important;
  flex: 1;
}
.btn-group button.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.7109375rem;
  line-height: 1.5;
  border-radius: 0.2rem;
  min-height: 35px;
}
.btn.active {
  background: #349ff5;
  color: white;
}
.btn.active .fa {
  line-height: 17px;
  color: #349ff5;
  padding: 7px 10px;
  border-radius: 100%;
  background: white;
  font-size: 10px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
}
.card-header {
  background: #2196f3 linear-gradient(180deg, #42a6f5, #2196f3) repeat-x;
  background: #0e6bb0 linear-gradient(180deg, #2588d2, #0e6bb0) repeat-x;
  color: white;
  text-align: center;
}
.breadcrumb-custom {
  background: #299af3;
  background: white;
}
.breadcrumb-custom .col {
  height: 25px;
}
.breadcrumb-custom .col span {
  color: white;
  position: absolute;
  line-height: 25px;
  left: 3%;
}
.breadcrumb-custom .col:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: var(--darkblue);
  border-right: 0px solid #c0e3ff;

  -webkit-transform-origin: 100% 100%;
  -ms-transform-origin: 100% 100%;
  transform-origin: 100% 100%;

  // -webkit-transform: skewX(30deg);
  // -ms-transform: skewX(30deg);
  // transform: skewX(30deg);
  transition: 1s;
}
.breadcrumb-custom .col.active:before {
  // background: #2196f3 linear-gradient(180deg, #42a6f5, #2196f3) repeat-x;
  // background: #0e6bb0 linear-gradient(180deg, #2588d2, #0e6bb0) repeat-x;
  background-color: var(--primary);
}
.load_ls_overlay {
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  background: #ffffff9c;
  z-index: 99;
  left: 0;
  top: 0;
  align-items: center;
  justify-content: center;
}
.Toastify__toast {
  border-radius: 10px !important;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 1rem;
}

.form-control {
  border: 1px solid #e0e0e0;
  //box-shadow: none !important;
  border-radius: 5px;
  padding: 0 15px !important;
  background-color: #f9f9f9;
}
.grecaptcha-badge {
  opacity: 0;
}
.badge-warning {
  color: white;
}
.d-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.navbar-dark .navbar-brand {
  color: #fff;
  font-weight: 800;
}

.checkboxgroup-accordion-content {
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
  max-height: 0;
}

.checkboxgroup-accordion-content.show {
  max-height: 500px; /* Set this to a value that is taller than the tallest content */
}
