.tooltip-container {
  position: relative;
  display: inline-flex;
  align-items: center;
  gap: 8px;
}

.tooltip-trigger {
  cursor: pointer;
  color: #6c757d;
  transition: color 0.2s ease;
}

.tooltip-trigger:hover {
  color: #495057;
}

.tooltip-content {
  position: absolute;
  right: 100%;
  bottom: 0;
  transform: translateY(100%);
  background-color: #333;
  color: white;
  padding: 12px 16px;
  border-radius: 4px;
  font-size: 14px;
  min-width: 200px;
  max-width: 300px;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  margin-right: 8px;
  line-height: 1.4;
  white-space: normal;
  word-wrap: break-word;
}

.tooltip-content::before {
  content: '';
  position: absolute;
  right: -6px;
  bottom: 12px;
  border-width: 6px;
  border-style: solid;
  border-color: transparent transparent transparent #333;
}
