$color-primary: #0e6bb0;
$color-success: #0b9a10;
$color-info: #9c27b0;
$color-danger: #e51c23;
$color-warning: #ff9800;
$color-dark: #222;
$color-secondary: #fff;
$color-light: #efefef;
$color-darkblue: #1366a8;

h4 {
  color: $color-primary;
}
.alert {
  font-weight: 600;
}

:export {
  primary: $color-primary;
  darkblue: $color-darkblue;
  success: $color-success;
  info: $color-info;
  danger: $color-danger;
  warning: $color-warning;
  dark: $color-dark;
  secondary: $color-secondary;
  ligth: $color-light;
}
