.btn {
  font-weight: 600;
}
// .btn-primary {
//   background: #0e6bb0 linear-gradient(180deg, #2588d2, #0e6bb0) repeat-x;
// }
// .btn-primary:hover {
//   background: #0d5992 linear-gradient(180deg, #1f73b1, #0d5992) repeat-x;
// }

// .btn-success {
//   background: #0b9a10 linear-gradient(180deg, #17c33d, #0b9a10) repeat-x;
// }
// .btn-success:hover {
//   background: #0a860e linear-gradient(180deg, #10a531, #0a840e) repeat-x;
// }
.btn-input {
  box-shadow: none !important;
  border: none !important;
  &.opened {
    box-shadow: 0px 0px 0px 2px #2799f4 !important;
  }
  &:not(.active) {
    background-color: #f7f7f7 !important;
    &:hover {
      background-color: #f0f0f0 !important;
    }
  }
}
